<template>
  <v-card flat>
    <v-card-text>
      <div class="info-wrapper">
        <v-row>
          <v-col cols="12">
            <i class="flaticon-profile-1 mr-2" />
            <b>{{ $t('PROFILE.NAME_LABEL') }}:</b> {{ currentUser.name }}
          </v-col>
          <v-col cols="12">
            <i class="flaticon-multimedia-2 mr-2" />
            <b>{{ $t('PROFILE.EMAIL_LABEL') }}:</b> {{ currentUser.email }}
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  padding: 30px;
  font-size: 16px
}
</style>