<template>
  <v-card>
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#info-tab">
        <v-toolbar-title>
          {{ $t("PROFILE.INFO_HEADER") }}
        </v-toolbar-title>
      </v-tab>
      <v-tab href="#display-preferences-tab">
        <v-toolbar-title>
          {{ $t("PROFILE.DISPLAY_PREFERENCES_HEADER") }}
        </v-toolbar-title>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="info-tab">
        <Information />
      </v-tab-item>
      <v-tab-item value="display-preferences-tab">
        <DisplayPreferences />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Information from "@/views/pages/profile/partials/Information.vue";
import DisplayPreferences from "@/views/pages/profile/partials/DisplayPreferences.vue";
import { TABLE_INIT_URL, GET_NORMALIZE_DATA_INIT } from "@/store/normalize_data.module";

export default {
  components: {
    Information,
    DisplayPreferences
  },

  data() {
    return {
      tab: "info-tab"
    };
  },
  methods:{
    //
  },
};
</script>

<style>
</style>