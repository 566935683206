<template>
  <v-card :disabled="isProcessing" :loading="isProcessing" flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" v-for="preferenceType in displayPreferences" :key="preferenceType.id">
          <v-select
            :value="getPreferenceTypeValue(preferenceType.slug)"
            :label="preferenceType.name"
            :items="preferenceType.preferences"
            item-text="name"
            item-value="id"
            @change="updateUserDisplayPreference($event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_DISPLAY_PREFERENCES,
  UPDATE_USER_DISPLAY_PREFERENCE
} from "@/store/displayPreferences.module";

export default {
  data() {
    return {
      displayPreferences: null,
      isProcessing: true
    };
  },

  computed: {
    ...mapGetters(["currentUser"])
  },

  mounted() {
    this.getPreferences();
  },

  methods: {
    getPreferences() {
      this.$store
        .dispatch(GET_DISPLAY_PREFERENCES)
        .then((response) => {
          this.displayPreferences = response;

          this.isProcessing = false;
        });
    },

    getPreferenceTypeValue(slug) {
      let value = this.currentUser.display_preferences &&
        this.currentUser.display_preferences[slug] ?
          this.currentUser.display_preferences[slug].id :
          null;

      if (!value) {
        let preferenceType = this.displayPreferences.find(type => type.slug === slug);

        let value = preferenceType.preferences.find(preference => preference.is_default == true);
      }

      return value;
    },

    updateUserDisplayPreference(id) {
      this.isProcessing = true;

      this.$store
        .dispatch(UPDATE_USER_DISPLAY_PREFERENCE, { display_preference_id: id })
        .then((response) => {
          this.isProcessing = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  padding: 30px;
  font-size: 16px
}
</style>